<template>
  <div>
    <!-- <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><a :to="{ name: 'user'}">Users</a></li>
        <li class="breadcrumb-item active" aria-current="page">Edit Users</li>
      </ol>
    </nav> -->
    <b-breadcrumb class="breadcrumpsCustom">
      <b-breadcrumb-item href="/admin/users">
        <!-- <b-icon icon="house-fill" scale="1.25" shift-v="1.25" aria-hidden="true"></b-icon> -->
        <feather-icon class="breadcrumpsCustomIcon" icon="HomeIcon" scale="1.25" shift-v="1.25" aria-hidden="true" />
        Users
      </b-breadcrumb-item>
      <b-breadcrumb-item active>Edit Users</b-breadcrumb-item>
    </b-breadcrumb>
    <!-- Media -->
    <b-media class="mb-2">
      <template #aside>
        <b-avatar
          ref="previewEl"
          :src="userData.avatar"
          :text="avatarText(userData.name)"
          :variant="`light-${resolveUserRoleVariant(userData.role)}`"
          size="90px"
          rounded
        />
      </template>
      <h4 class="mt-3">
        {{ userData.name }}
      </h4>
    </b-media>

    <!-- User Info: Input Fields -->
    <b-form>
      <b-row>
        <!-- Field: Username -->
        <b-col cols="12" md="4">
          <b-form-group label="Username" label-for="username">
            <b-form-input id="username" v-model="userData.name" />
          </b-form-group>
        </b-col>

        <!-- Field: Full Name -->
        <b-col cols="12" md="4">
          <b-form-group label="Name" label-for="full-name">
            <b-form-input id="full-name" v-model="userData.name" />
          </b-form-group>
        </b-col>

        <!-- Field: Email -->
        <b-col cols="12" md="4">
          <b-form-group label="Email" label-for="email">
            <b-form-input id="email" v-model="userData.email" type="email" disabled />
          </b-form-group>
        </b-col>

        <!-- Field: Status -->
        <b-col cols="12" md="4">
          <b-form-group label="Status" label-for="user-status">
            <v-select
              v-model="userData.active"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="statusOptions"
              :reduce="(val) => val.active"
              :getOptionLabel="(status) => status.label"
              :clearable="false"
              input-id="user-status"
              @change="changeActiveStatus()"
            />
          </b-form-group>
        </b-col>

        <!-- Field: Role -->
        <!-- :selected="userData.groups.name" -->
        <!-- v-bind:value="userData.groups.name" -->
        <b-col cols="12" md="4">
          <b-form-group label="User Role" label-for="user-role">
            <v-select
              v-model="userData.group_id"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="groupOption"
              :reduce="(group) => group.id"
              :getOptionLabel="(group) => group.name"
              :clearable="false"
              input-id="user-role"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-form>

    <!-- Action Buttons -->
    <b-button variant="primary" class="mb-1 mb-sm-0 mr-0 mr-sm-1" :block="$store.getters['app/currentBreakPoint'] === 'xs'" @click="updateUser()">
      Save Changes
    </b-button>

    <b-button variant="danger" class="mb-1 mb-sm-0 mr-0 mr-sm-1" v-show="!userActive" @click="deleteUser()">Delete User</b-button>

    <b-button variant="primary" class="mb-1 mb-sm-0 mr-0 mr-sm-1" @click="resendEmail(userData.email)">Resend Email</b-button>

    <b-row class="mt-2">
      <b-col cols="12">
        <h5>Permitted Area</h5>
      </b-col>
      <b-col cols="12" md="4" v-if="filter_data">
        <b-form-group label="State" label-for="state">
          <v-select
            v-model="user_permitted_state"
            :options="filter_data.states"
            :reduce="(s) => s.state"
            :clearable="true"
            :label="'state'"
            class="w-100 capitalize-text"
            :placeholder="'Select state'"
            :multiple="false"
            :disabled="false"
            input-id="state"
          />
        </b-form-group>
      </b-col>
      <b-col cols="12" md="4" v-if="filter_data">
        <b-form-group label="Council" label-for="council">
          <v-select
            v-model="user_permitted_council"
            :options="filter_data.councils"
            :reduce="(c) => c.council"
            :clearable="true"
            :label="'council'"
            class="w-100 capitalize-text"
            :placeholder="'Select state'"
            :multiple="false"
            :disabled="true"
            input-id="council"
          />
        <small class="mt-1 pt-1 text-warning" >Empty value will result in state wide access</small>
        </b-form-group>
      </b-col>
      <b-col cols="12">
        <b-button variant="primary" class="mb-1 mb-sm-0 mr-0 mr-sm-1 pab" @click="update_coverage_area()">
          <b-spinner v-if="busy" small type="grow" class="mr-50"></b-spinner>
          Update
        </b-button>
        <b-button variant="danger" class="mb-1 mb-sm-0 mr-0 mr-sm-1 pab" @click="remove_coverage_area()">
          <b-spinner v-if="busy" small type="grow" class="mr-50"></b-spinner>
          Remove
        </b-button>
        <b-button variant="warning" class="mb-1 mb-sm-0 mr-0 mr-sm-1 pab" @click="get_coverage_area()">
          <b-spinner v-if="busy" small type="grow" class="mr-50"></b-spinner>
          Refresh
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import moment from "moment";
import {
  BButton,
  BMedia,
  BAvatar,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BTable,
  BCard,
  BCardHeader,
  BCardTitle,
  BFormCheckbox,
  BBreadcrumb,
  BIcon,
  BBreadcrumbItem,
  BSpinner,
} from "bootstrap-vue";
import { avatarText } from "@core/utils/filter";
import vSelect from "vue-select";
import router from "@/router";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Vue from "vue";

export default {
  components: {
    BButton,
    BMedia,
    BAvatar,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BTable,
    BCard,
    BCardHeader,
    BCardTitle,
    BFormCheckbox,
    vSelect,
    ToastificationContent,
    BBreadcrumb,
    BIcon,
    BBreadcrumbItem,
    BSpinner,
  },
  data() {
    return {
      userData: {},
      groupOption: [],
      userActive: false,
      avatarText,
      statusOptions: [
        { label: "Active", value: "active", active: true },
        { label: "Inactive", value: "inactive", active: false },
      ],

      // listings section filter data
      filter_data: null,
      local_storage_keyname: "listings_filter_data",
      user_permitted_state: null,
      user_permitted_council: null,
      busy: false,
    };
  },
  async mounted() {
    await this.fetchUserByID();
    await this.fetchGroups();
    await this.get_filter_data();
    await this.get_coverage_area();
    console.log(this.filter_data);
  },
  methods: {
    async fetchUserByID() {
      this.$vs.loading();
      const res = await this.$axios.get(`${this.$API_URL_USER}/fetchUsersByID/${router.currentRoute.params.id}`);
      this.userData = res.data.response;
      this.userActive = this.userData.active;
      this.$vs.loading.close();
    },

    async updateUser() {
      this.$vs.loading();
      let userUpdate = await this.$axios.post(`${this.$API_URL_USER}/updateUser`, {
        data: this.userData,
      });

      if (userUpdate.data.success) {
        this.$vs.loading.close();
        Vue.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: `Success`,
            icon: "CoffeeIcon",
            variant: "success",
            text: `User has been update successfully!`,
          },
        });

        this.fetchUserByID();
      } else {
        this.$vs.loading.close();
        Vue.$toast({
          component: ToastificationContent,
          props: {
            title: "Something went wrong",
            icon: "EditIcon",
            variant: "danger",
          },
        });
      }
    },

    async fetchGroups() {
      const res = await this.$axios.get(`${this.$API_URL_GROUPS}/fetchGroups`);
      let group = await res;
      this.groupOption = group.data.response;
      return;
    },

    async deleteUser() {
      this.$vs.loading();
      let userDelete = await this.$axios.get(`${this.$API_URL_USER}/deleteUser/${this.userData.id}`);

      if (userDelete.data.success) {
        this.$vs.loading.close();
        Vue.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: `Success`,
            icon: "CoffeeIcon",
            variant: "success",
            text: `User has been deleted successfully!`,
          },
        });

        let url = "/admin/users";
        console.log(url);
        setTimeout(() => {
          this.$router.push(url);
        }, 1000);
      } else {
        this.$vs.loading.close();
        Vue.$toast({
          component: ToastificationContent,
          props: {
            title: "Something went wrong",
            icon: "EditIcon",
            variant: "danger",
          },
        });
      }
    },

    async changeActiveStatus() {
      this.userData.active = !this.userData.active;
    },

    async resendEmail(email) {
      this.$vs.loading();
      let resendEmail = await this.$axios.get(`${this.$API_URL_USER}/resendEmail/${email}`);

      if (resendEmail.data.success) {
        this.$vs.loading.close();
        Vue.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: `Success`,
            icon: "CoffeeIcon",
            variant: "success",
            text: `Email Resend!`,
          },
        });
      } else {
        this.$vs.loading.close();
        Vue.$toast({
          component: ToastificationContent,
          props: {
            title: "Something went wrong",
            icon: "EditIcon",
            variant: "danger",
          },
        });
      }
    },

    resolveUserRoleVariant(role) {
      if (role === "subscriber") return "primary";
      if (role === "author") return "warning";
      if (role === "maintainer") return "success";
      if (role === "editor") return "info";
      if (role === "admin") return "danger";
      return "primary";
    },
    async get_filter_data() {
      try {
        let yesterday = moment().subtract(2, "days").format("YYYY-MM-DD");
        let filter_data = localStorage.getItem(this.local_storage_keyname);
        filter_data = JSON.parse(filter_data);

        //   checking if valid filters exist in local storage & not 2x days old
        if (filter_data && filter_data.date > yesterday) this.filter_data = filter_data;
        else {
          //   fetching fresh filter data if not found in local storage || 1x day old
          let res = await this.$axios.get(`${this.$API_BASE_LISTINGS}/getlistingsfilterdata`, { filters: [], options: this.options });
          if (!res.data.success) throw new Error("Could not fetch filter data - check your internet connection");
          this.filter_data = res.data.filter_data;
          // setting filter data for component & local storage
          localStorage.setItem(this.local_storage_keyname, JSON.stringify(this.filter_data));
        }
        // this.showToast("info", "Filter Data Updated");
      } catch (error) {
        console.log(error);
        localStorage.removeItem(this.local_storage_keyname);
        this.showToast("danger", "Error While Updating Filter Data");
      }
    },
    showToast(variant = "info", title = "Info", text = "") {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon: "BellIcon",
          text,
          variant,
        },
      });
    },
    async get_coverage_area() {
      try {
        if (!this.userData.id) throw new Error("Invalid user");
        this.busy = true;
        let res = await this.$axios.post(`${this.$API_BASE_LISTINGS}/getuserpermittedstate`, { user_id: this.userData.id });
        console.log(res.data);
        this.busy = false;
        if (!res.data.success) throw new Error("Could not get user's permitted area");

        this.user_permitted_state = res.data.coverage_area.state;
        this.user_permitted_council = res.data.coverage_area.council;
      } catch (error) {
        this.busy = false;
        console.log(error);
        this.showToast("danger", "Error", error.message ? error.message : "Permitted area could not be removed");
      }
    },
    async update_coverage_area() {
      console.log(this.user_permitted_state, this.userData.id);
      try {
        if (!this.user_permitted_state || !this.userData.id) throw new Error("State value can not be null");
        this.busy = true;
        let res = await this.$axios.post(`${this.$API_BASE_LISTINGS}/updateuserpermittedstate`, {
          state: this.user_permitted_state,
          user_id: this.userData.id,
        });
        console.log(res.data);
        this.busy = false;
        if (!res.data.success) throw new Error("Could not update user's permitted area");
        this.showToast("success", "User Updated", "Updated permitted area");
      } catch (error) {
        this.busy = false;
        console.log(error);
        this.showToast("danger", "Error", error.message ? error.message : "Permitted area could not be updated");
      }
    },
    async remove_coverage_area() {
      try {
        if (!this.userData.id) throw new Error("Invalid user");
        this.busy = true;
        let res = await this.$axios.post(`${this.$API_BASE_LISTINGS}/removeuserpermittedstate`, {
          user_id: this.userData.id,
        });
        console.log(res.data);
        this.busy = false;
        if (!res.data.success) throw new Error("Could not remove user's permitted area");
        this.showToast("success", "User Updated", "Removed permitted area");

        this.user_permitted_state = null;
        this.user_permitted_council = null;
      } catch (error) {
        this.busy = false;
        console.log(error);
        this.showToast("danger", "Error", error.message ? error.message : "Permitted area could not be removed");
      }
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
.pab {
  width: 150px;
}
</style>
