<template>
  <component :is="userData ? 'div' : 'b-card'">

    <!-- Alert: No item found -->
    <b-alert variant="danger" :show="userData === undefined">
      <h4 class="alert-heading">
        Error fetching user data
      </h4>
      <div class="alert-body">
        No user found with this user id. Check
        <b-link class="alert-link" :to="{ name: 'apps-users-list' }">
          User List
        </b-link>
        for other users.
      </div>
    </b-alert>

    <b-tabs v-if="userData" pills>
      <!-- Tab: Account -->
      <user-edit-tab-account :user-data="userData" class="mt-2 pt-75" />
    </b-tabs>
  </component>
</template>

<script>
import {
  BTab, BTabs, BCard, BAlert, BLink,
} from 'bootstrap-vue'
import { ref, onUnmounted } from '@vue/composition-api'
import router from '@/router'
import UserEditTabAccount from './UserEditTabAccount.vue'
import UserEditTabInformation from './UserEditTabInformation.vue'
import UserEditTabSocial from './UserEditTabSocial.vue'
import { methods } from 'vue-echarts'

export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,

    UserEditTabAccount,
    UserEditTabInformation,
    UserEditTabSocial
  },
  data() {
    return {
      userData: ref(null),
    };
  },
  mounted: function () {
    this.fetchUserByID();
  },
  methods: {
    async fetchUserByID() {
      const res = await this.$axios.get(`${this.$API_URL_USER}/fetchUsersByID/${router.currentRoute.params.id}`);
      return this.userData = res.data.response;
    }
  }
}
</script>

<style>

</style>
